import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Provisioners verify the legitimacy of certificate signing requests and attest to the identity of the requesting service or human.
Used to help bootstrap new entities into the PKI,
each Provisioner addresses a particular environment, enabling different use cases.
A few examples include:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`OIDC Provisioner`}</strong>{` - Useful for getting certificates to people,
the OAuth/OpenID Connect (OIDC) Provisioner uses identity tokens for authentication.
With this provisioner, you can use single sign-on with G Suite, Okta, Azure Active Directory, or any other OAuth OIDC provider
to verify the user's identity before issuing a certificate.`}</li>
      <li parentName="ul"><strong parentName="li">{`ACME Provisioner`}</strong>{` - Useful for automating TLS certificates,
the ACME provisioner provides CSR generation, domain ownership verification, certificate download, and installation.
With support for all of the ACME challenge types supported by Let’s Encrypt (HTTP, DNS, ALPN),
the ACME provisioner unlocks the entire ACME ecosystem of tools and clients.`}</li>
      <li parentName="ul"><strong parentName="li">{`Cloud API Provisioners`}</strong>{` - Useful for issuing certificates to public cloud virtual machines,
Cloud API Provisioners use the native cloud provider API and instance identity documents to automate certificates.
With support for AWS, GCP, and Azure metadata APIs, the Cloud API provisioner accelerates secure cloud operations.`}</li>
      <li parentName="ul"><strong parentName="li">{`JWK Provisioner`}</strong>{` - Useful for a broad range of workflows, the JWK provisioner provides a flexible JSON Web Token-based authentication flow.
Often paired with infrastructure automation solutions, the JWK Provisioner can deliver one-time tokens to a new workload to later be exchanged for an x.509 certificate.`}</li>
    </ul>
    <h2>{`Learn More`}</h2>
    <p>{`Provisioners are an open-source feature.
Visit `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-ca/certificate-authority-core-concepts#provisioners"
      }}>{`the `}<inlineCode parentName="a">{`step-ca`}</inlineCode>{` documentation`}</a>{` for a complete list of Provisioners and instructions on usage and capabilities of each.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      